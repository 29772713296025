/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes-étape
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import type {
  ActivityPassJsonldReadActivityPass,
  ApiActivityPassesGetCollection200,
  ApiBookingsBookingIdguestsGetCollection200,
  ApiBookingsGetCollection200,
  ApiCountriesGetCollection200,
  ApiCustomerStepsGetCollection200,
  ApiFavoritesListGetCollection200,
  ApiFavoritesListGetCollectionParams,
  ApiFavoritesListListIdfavoritesGetCollection200,
  ApiFavoritesListListIdfavoritesGetCollectionParams,
  ApiFavoritesListListIdusersGetCollection200,
  ApiFavoritesListListIdusersGetCollectionParams,
  ApiFavoritesListPublicIdfavoritespublicGetCollection200,
  ApiFavoritesListPublicIdfavoritespublicGetCollectionParams,
  ApiFilestoragefilePostBody,
  ApiLocalitiesGetCollection200,
  ApiPaymentStepsGetCollection200,
  ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams,
  ApiRentalsRentalIdbadgesGetCollection200,
  ApiSatisfactionSurveysrentalRentalIdGetCollection200,
  ApiStayDateAvailablesGetCollection200,
  ApiVouchersGetCollection200,
  BookingBookingCreateDtoCreateBooking,
  BookingBookingCreateDtoJsonldCreateBooking,
  BookingCancellationFeeJsonldBookingCancellationGet,
  BookingCancellationRequestBookingCancel,
  BookingCancellationRequestJsonldBookingCancel,
  BookingFeeJsonldReadFee,
  BookingGuestJsonldReadBookingGuest,
  BookingGuestJsonldWriteBookingGuest,
  BookingGuestWriteBookingGuest,
  BookingInsuranceJsonldReadBookingInsurance,
  BookingJsonldBookingCancel,
  BookingJsonldReadBooking,
  BookingTrackingPostBookingJsonldReadTrackingPostBooking,
  BookingWriteBooking,
  CompensationJsonldReadCompensation,
  ConfigJsonldReadConfig,
  CountryJsonldReadCountry,
  CustomerCustomerInputDtoWriteCustomer,
  CustomerJsonldReadCustomer,
  CustomerStepJsonldReadCustomerStep,
  FilestorageJsonldReadFile,
  LocalityJsonldReadLocality,
  OwnerJsonldReadOwner,
  PaymentStepJsonldReadPaymentStep,
  PaymentStepWritePaymentStep,
  PromotionalCodeUsedJsonldReadPromotionalCodeUsed,
  RentalFavoriteFavoriteCreate,
  RentalFavoriteJsonldFavoriteCreate,
  RentalFavoriteJsonldFavoriteGet,
  RentalFavoriteListFavoriteListCreate,
  RentalFavoriteListFavoriteListUpdate,
  RentalFavoriteListJsonldFavoriteListCreate,
  RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental,
  RentalFavoriteListJsonldFavoriteListUpdate,
  RentalFavoriteUserFavoriteUserCreate,
  RentalFavoriteUserJsonldFavoriteUserCreate,
  RentalFavoriteUserJsonldFavoriteUserGet,
  RentalJsonldReadRental,
  RentalStayAvailableJsonldReadRentalStayAvailable,
  ReviewJsonldReadReview,
  SatisfactionSurveyJsonldReadSatisfactionSurvey,
  StayCheckoutJsonldReadStayCheckout,
  StayCheckoutStayCheckoutCreateDtoCreateStayCheckout,
  StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout,
  StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout,
  UserJsonldReadMe,
  VoucherJsonldReadVoucher,
} from './model';
import { instance } from '../mutator/instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieves the collection of ActivityPass resources.
 * @summary Retrieves the collection of ActivityPass resources.
 */
export const apiActivityPassesGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiActivityPassesGetCollection200>(
    { url: `/activity_passes`, method: 'GET', signal },
    options
  );
};

export const getApiActivityPassesGetCollectionQueryKey = () => {
  return [`/activity_passes`] as const;
};

export const getApiActivityPassesGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiActivityPassesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>
  > = ({ signal }) => apiActivityPassesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiActivityPassesGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiActivityPassesGetCollection>>
>;
export type ApiActivityPassesGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of ActivityPass resources.
 */
export const useApiActivityPassesGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiActivityPassesGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiActivityPassesGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiActivityPassesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>
  > = ({ signal }) => apiActivityPassesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiActivityPassesGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiActivityPassesGetCollection>>
>;
export type ApiActivityPassesGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of ActivityPass resources.
 */
export const useApiActivityPassesGetCollection = <
  TData = Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiActivityPassesGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a ActivityPass resource.
 * @summary Retrieves a ActivityPass resource.
 */
export const apiActivityPassesIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ActivityPassJsonldReadActivityPass>(
    { url: `/activity_passes/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiActivityPassesIdGetQueryKey = (id: string) => {
  return [`/activity_passes/${id}`] as const;
};

export const getApiActivityPassesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiActivityPassesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiActivityPassesIdGet>>
  > = ({ signal }) => apiActivityPassesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiActivityPassesIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiActivityPassesIdGet>>
>;
export type ApiActivityPassesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a ActivityPass resource.
 */
export const useApiActivityPassesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiActivityPassesIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiActivityPassesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiActivityPassesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiActivityPassesIdGet>>
  > = ({ signal }) => apiActivityPassesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiActivityPassesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiActivityPassesIdGet>>
>;
export type ApiActivityPassesIdGetQueryError = void;

/**
 * @summary Retrieves a ActivityPass resource.
 */
export const useApiActivityPassesIdGet = <
  TData = Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiActivityPassesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiActivityPassesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a BookingFee resource.
 * @summary Retrieves a BookingFee resource.
 */
export const apiBookingFeesIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<BookingFeeJsonldReadFee>(
    { url: `/booking_fees/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiBookingFeesIdGetQueryKey = (id: string) => {
  return [`/booking_fees/${id}`] as const;
};

export const getApiBookingFeesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiBookingFeesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingFeesIdGet>>
  > = ({ signal }) => apiBookingFeesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingFeesIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingFeesIdGet>>
>;
export type ApiBookingFeesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a BookingFee resource.
 */
export const useApiBookingFeesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingFeesIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingFeesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiBookingFeesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingFeesIdGet>>
  > = ({ signal }) => apiBookingFeesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingFeesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingFeesIdGet>>
>;
export type ApiBookingFeesIdGetQueryError = void;

/**
 * @summary Retrieves a BookingFee resource.
 */
export const useApiBookingFeesIdGet = <
  TData = Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingFeesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingFeesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a BookingInsurance resource.
 * @summary Retrieves a BookingInsurance resource.
 */
export const apiBookingInsurancesIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<BookingInsuranceJsonldReadBookingInsurance>(
    { url: `/booking_insurances/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiBookingInsurancesIdGetQueryKey = (id: string) => {
  return [`/booking_insurances/${id}`] as const;
};

export const getApiBookingInsurancesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiBookingInsurancesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>
  > = ({ signal }) => apiBookingInsurancesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingInsurancesIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>
>;
export type ApiBookingInsurancesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a BookingInsurance resource.
 */
export const useApiBookingInsurancesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingInsurancesIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingInsurancesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiBookingInsurancesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>
  > = ({ signal }) => apiBookingInsurancesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingInsurancesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>
>;
export type ApiBookingInsurancesIdGetQueryError = void;

/**
 * @summary Retrieves a BookingInsurance resource.
 */
export const useApiBookingInsurancesIdGet = <
  TData = Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingInsurancesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingInsurancesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of Booking resources.
 * @summary Retrieves the collection of Booking resources.
 */
export const apiBookingsGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiBookingsGetCollection200>(
    { url: `/bookings`, method: 'GET', signal },
    options
  );
};

export const getApiBookingsGetCollectionQueryKey = () => {
  return [`/bookings`] as const;
};

export const getApiBookingsGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiBookingsGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>
  > = ({ signal }) => apiBookingsGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsGetCollection>>
>;
export type ApiBookingsGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of Booking resources.
 */
export const useApiBookingsGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingsGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiBookingsGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>
  > = ({ signal }) => apiBookingsGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsGetCollection>>
>;
export type ApiBookingsGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of Booking resources.
 */
export const useApiBookingsGetCollection = <
  TData = Awaited<ReturnType<typeof apiBookingsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a Booking resource.
 * @summary Creates a Booking resource.
 */
export const apiBookingsPost = (
  apiBookingsPostBody:
    | BookingBookingCreateDtoJsonldCreateBooking
    | BookingBookingCreateDtoCreateBooking,
  options?: SecondParameter<typeof instance>
) => {
  return instance<BookingJsonldReadBooking>(
    { url: `/bookings`, method: 'POST', data: apiBookingsPostBody },
    options
  );
};

export const getApiBookingsPostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsPost>>,
    TError,
    {
      data:
        | BookingBookingCreateDtoJsonldCreateBooking
        | BookingBookingCreateDtoCreateBooking;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiBookingsPost>>,
  TError,
  {
    data:
      | BookingBookingCreateDtoJsonldCreateBooking
      | BookingBookingCreateDtoCreateBooking;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiBookingsPost>>,
    {
      data:
        | BookingBookingCreateDtoJsonldCreateBooking
        | BookingBookingCreateDtoCreateBooking;
    }
  > = (props) => {
    const { data } = props ?? {};

    return apiBookingsPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiBookingsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsPost>>
>;
export type ApiBookingsPostMutationBody =
  | BookingBookingCreateDtoJsonldCreateBooking
  | BookingBookingCreateDtoCreateBooking;
export type ApiBookingsPostMutationError = void;

/**
 * @summary Creates a Booking resource.
 */
export const useApiBookingsPost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsPost>>,
    TError,
    {
      data:
        | BookingBookingCreateDtoJsonldCreateBooking
        | BookingBookingCreateDtoCreateBooking;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiBookingsPost>>,
  TError,
  {
    data:
      | BookingBookingCreateDtoJsonldCreateBooking
      | BookingBookingCreateDtoCreateBooking;
  },
  TContext
> => {
  const mutationOptions = getApiBookingsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves the collection of BookingGuest resources.
 * @summary Retrieve the guests of the booking.
 */
export const apiBookingsBookingIdguestsGetCollection = (
  bookingId: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiBookingsBookingIdguestsGetCollection200>(
    { url: `/bookings/${bookingId}/guests`, method: 'GET', signal },
    options
  );
};

export const getApiBookingsBookingIdguestsGetCollectionQueryKey = (
  bookingId: string
) => {
  return [`/bookings/${bookingId}/guests`] as const;
};

export const getApiBookingsBookingIdguestsGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
  TError = unknown
>(
  bookingId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiBookingsBookingIdguestsGetCollectionQueryKey(bookingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>
  > = ({ signal }) =>
    apiBookingsBookingIdguestsGetCollection(bookingId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bookingId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsBookingIdguestsGetCollectionInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>
  >;
export type ApiBookingsBookingIdguestsGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieve the guests of the booking.
 */
export const useApiBookingsBookingIdguestsGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
  TError = unknown
>(
  bookingId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiBookingsBookingIdguestsGetCollectionInfiniteQueryOptions(
      bookingId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingsBookingIdguestsGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
  TError = unknown
>(
  bookingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiBookingsBookingIdguestsGetCollectionQueryKey(bookingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>
  > = ({ signal }) =>
    apiBookingsBookingIdguestsGetCollection(bookingId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bookingId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsBookingIdguestsGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>
>;
export type ApiBookingsBookingIdguestsGetCollectionQueryError = unknown;

/**
 * @summary Retrieve the guests of the booking.
 */
export const useApiBookingsBookingIdguestsGetCollection = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
  TError = unknown
>(
  bookingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsBookingIdguestsGetCollectionQueryOptions(
    bookingId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a BookingGuest resource.
 * @summary Create a guest for the booking.
 */
export const apiBookingsBookingIdguestsPost = (
  bookingId: string,
  apiBookingsBookingIdguestsPostBody:
    | BookingGuestJsonldWriteBookingGuest
    | BookingGuestWriteBookingGuest,
  options?: SecondParameter<typeof instance>
) => {
  return instance<BookingGuestJsonldReadBookingGuest>(
    {
      url: `/bookings/${bookingId}/guests`,
      method: 'POST',
      data: apiBookingsBookingIdguestsPostBody,
    },
    options
  );
};

export const getApiBookingsBookingIdguestsPostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsPost>>,
    TError,
    {
      bookingId: string;
      data: BookingGuestJsonldWriteBookingGuest | BookingGuestWriteBookingGuest;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsPost>>,
  TError,
  {
    bookingId: string;
    data: BookingGuestJsonldWriteBookingGuest | BookingGuestWriteBookingGuest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsPost>>,
    {
      bookingId: string;
      data: BookingGuestJsonldWriteBookingGuest | BookingGuestWriteBookingGuest;
    }
  > = (props) => {
    const { bookingId, data } = props ?? {};

    return apiBookingsBookingIdguestsPost(bookingId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiBookingsBookingIdguestsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsPost>>
>;
export type ApiBookingsBookingIdguestsPostMutationBody =
  | BookingGuestJsonldWriteBookingGuest
  | BookingGuestWriteBookingGuest;
export type ApiBookingsBookingIdguestsPostMutationError = void;

/**
 * @summary Create a guest for the booking.
 */
export const useApiBookingsBookingIdguestsPost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsPost>>,
    TError,
    {
      bookingId: string;
      data: BookingGuestJsonldWriteBookingGuest | BookingGuestWriteBookingGuest;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsPost>>,
  TError,
  {
    bookingId: string;
    data: BookingGuestJsonldWriteBookingGuest | BookingGuestWriteBookingGuest;
  },
  TContext
> => {
  const mutationOptions =
    getApiBookingsBookingIdguestsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a BookingGuest resource.
 * @summary Retrieve a guest of the booking.
 */
export const apiBookingsBookingIdguestsIdGet = (
  bookingId: string,
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<BookingGuestJsonldReadBookingGuest>(
    { url: `/bookings/${bookingId}/guests/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiBookingsBookingIdguestsIdGetQueryKey = (
  bookingId: string,
  id: string
) => {
  return [`/bookings/${bookingId}/guests/${id}`] as const;
};

export const getApiBookingsBookingIdguestsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
  TError = void
>(
  bookingId: string,
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiBookingsBookingIdguestsIdGetQueryKey(bookingId, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>
  > = ({ signal }) =>
    apiBookingsBookingIdguestsIdGet(bookingId, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(bookingId && id),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsBookingIdguestsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>
>;
export type ApiBookingsBookingIdguestsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieve a guest of the booking.
 */
export const useApiBookingsBookingIdguestsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
  TError = void
>(
  bookingId: string,
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsBookingIdguestsIdGetInfiniteQueryOptions(
    bookingId,
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingsBookingIdguestsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
  TError = void
>(
  bookingId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiBookingsBookingIdguestsIdGetQueryKey(bookingId, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>
  > = ({ signal }) =>
    apiBookingsBookingIdguestsIdGet(bookingId, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(bookingId && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsBookingIdguestsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>
>;
export type ApiBookingsBookingIdguestsIdGetQueryError = void;

/**
 * @summary Retrieve a guest of the booking.
 */
export const useApiBookingsBookingIdguestsIdGet = <
  TData = Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
  TError = void
>(
  bookingId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsBookingIdguestsIdGetQueryOptions(
    bookingId,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Updates the BookingGuest resource.
 * @summary Update a guest of the booking.
 */
export const apiBookingsBookingIdguestsIdPatch = (
  bookingId: string,
  id: string,
  bookingGuestWriteBookingGuest: BookingGuestWriteBookingGuest,
  options?: SecondParameter<typeof instance>
) => {
  return instance<BookingGuestJsonldReadBookingGuest>(
    {
      url: `/bookings/${bookingId}/guests/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/merge-patch+json' },
      data: bookingGuestWriteBookingGuest,
    },
    options
  );
};

export const getApiBookingsBookingIdguestsIdPatchMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdPatch>>,
    TError,
    { bookingId: string; id: string; data: BookingGuestWriteBookingGuest },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdPatch>>,
  TError,
  { bookingId: string; id: string; data: BookingGuestWriteBookingGuest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdPatch>>,
    { bookingId: string; id: string; data: BookingGuestWriteBookingGuest }
  > = (props) => {
    const { bookingId, id, data } = props ?? {};

    return apiBookingsBookingIdguestsIdPatch(
      bookingId,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiBookingsBookingIdguestsIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdPatch>>
>;
export type ApiBookingsBookingIdguestsIdPatchMutationBody =
  BookingGuestWriteBookingGuest;
export type ApiBookingsBookingIdguestsIdPatchMutationError = void;

/**
 * @summary Update a guest of the booking.
 */
export const useApiBookingsBookingIdguestsIdPatch = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdPatch>>,
    TError,
    { bookingId: string; id: string; data: BookingGuestWriteBookingGuest },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiBookingsBookingIdguestsIdPatch>>,
  TError,
  { bookingId: string; id: string; data: BookingGuestWriteBookingGuest },
  TContext
> => {
  const mutationOptions =
    getApiBookingsBookingIdguestsIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a Booking resource.
 * @summary Retrieves a Booking resource.
 */
export const apiBookingsIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<BookingJsonldReadBooking>(
    { url: `/bookings/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiBookingsIdGetQueryKey = (id: string) => {
  return [`/bookings/${id}`] as const;
};

export const getApiBookingsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiBookingsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsIdGet>>
  > = ({ signal }) => apiBookingsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdGet>>
>;
export type ApiBookingsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Booking resource.
 */
export const useApiBookingsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiBookingsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsIdGet>>
  > = ({ signal }) => apiBookingsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdGet>>
>;
export type ApiBookingsIdGetQueryError = void;

/**
 * @summary Retrieves a Booking resource.
 */
export const useApiBookingsIdGet = <
  TData = Awaited<ReturnType<typeof apiBookingsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Updates the Booking resource.
 * @summary Updates the Booking resource.
 */
export const apiBookingsIdPatch = (
  id: string,
  bookingWriteBooking: BookingWriteBooking,
  options?: SecondParameter<typeof instance>
) => {
  return instance<BookingJsonldReadBooking>(
    {
      url: `/bookings/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/merge-patch+json' },
      data: bookingWriteBooking,
    },
    options
  );
};

export const getApiBookingsIdPatchMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsIdPatch>>,
    TError,
    { id: string; data: BookingWriteBooking },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiBookingsIdPatch>>,
  TError,
  { id: string; data: BookingWriteBooking },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiBookingsIdPatch>>,
    { id: string; data: BookingWriteBooking }
  > = (props) => {
    const { id, data } = props ?? {};

    return apiBookingsIdPatch(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiBookingsIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdPatch>>
>;
export type ApiBookingsIdPatchMutationBody = BookingWriteBooking;
export type ApiBookingsIdPatchMutationError = void;

/**
 * @summary Updates the Booking resource.
 */
export const useApiBookingsIdPatch = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsIdPatch>>,
    TError,
    { id: string; data: BookingWriteBooking },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiBookingsIdPatch>>,
  TError,
  { id: string; data: BookingWriteBooking },
  TContext
> => {
  const mutationOptions = getApiBookingsIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Replaces the Booking resource.
 * @summary Replaces the Booking resource.
 */
export const apiBookingsIdcancelPut = (
  id: string,
  apiBookingsIdcancelPutBody:
    | BookingCancellationRequestJsonldBookingCancel
    | BookingCancellationRequestBookingCancel,
  options?: SecondParameter<typeof instance>
) => {
  return instance<BookingJsonldBookingCancel>(
    {
      url: `/bookings/${id}/cancel`,
      method: 'PUT',
      data: apiBookingsIdcancelPutBody,
    },
    options
  );
};

export const getApiBookingsIdcancelPutMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsIdcancelPut>>,
    TError,
    {
      id: string;
      data:
        | BookingCancellationRequestJsonldBookingCancel
        | BookingCancellationRequestBookingCancel;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiBookingsIdcancelPut>>,
  TError,
  {
    id: string;
    data:
      | BookingCancellationRequestJsonldBookingCancel
      | BookingCancellationRequestBookingCancel;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiBookingsIdcancelPut>>,
    {
      id: string;
      data:
        | BookingCancellationRequestJsonldBookingCancel
        | BookingCancellationRequestBookingCancel;
    }
  > = (props) => {
    const { id, data } = props ?? {};

    return apiBookingsIdcancelPut(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiBookingsIdcancelPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdcancelPut>>
>;
export type ApiBookingsIdcancelPutMutationBody =
  | BookingCancellationRequestJsonldBookingCancel
  | BookingCancellationRequestBookingCancel;
export type ApiBookingsIdcancelPutMutationError = void;

/**
 * @summary Replaces the Booking resource.
 */
export const useApiBookingsIdcancelPut = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiBookingsIdcancelPut>>,
    TError,
    {
      id: string;
      data:
        | BookingCancellationRequestJsonldBookingCancel
        | BookingCancellationRequestBookingCancel;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiBookingsIdcancelPut>>,
  TError,
  {
    id: string;
    data:
      | BookingCancellationRequestJsonldBookingCancel
      | BookingCancellationRequestBookingCancel;
  },
  TContext
> => {
  const mutationOptions = getApiBookingsIdcancelPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a Booking resource.
 * @summary Retrieves a Booking resource.
 */
export const apiBookingsIdcancellationFeeGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<BookingCancellationFeeJsonldBookingCancellationGet>(
    { url: `/bookings/${id}/cancellation-fee`, method: 'GET', signal },
    options
  );
};

export const getApiBookingsIdcancellationFeeGetQueryKey = (id: string) => {
  return [`/bookings/${id}/cancellation-fee`] as const;
};

export const getApiBookingsIdcancellationFeeGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiBookingsIdcancellationFeeGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>
  > = ({ signal }) =>
    apiBookingsIdcancellationFeeGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsIdcancellationFeeGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>
>;
export type ApiBookingsIdcancellationFeeGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Booking resource.
 */
export const useApiBookingsIdcancellationFeeGetInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsIdcancellationFeeGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingsIdcancellationFeeGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiBookingsIdcancellationFeeGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>
  > = ({ signal }) =>
    apiBookingsIdcancellationFeeGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsIdcancellationFeeGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>
>;
export type ApiBookingsIdcancellationFeeGetQueryError = void;

/**
 * @summary Retrieves a Booking resource.
 */
export const useApiBookingsIdcancellationFeeGet = <
  TData = Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdcancellationFeeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsIdcancellationFeeGetQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Booking resource.
 * @summary Retrieves a Booking resource.
 */
export const apiBookingsIdtrackingPostBookingGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<BookingTrackingPostBookingJsonldReadTrackingPostBooking>(
    { url: `/bookings/${id}/tracking-post-booking`, method: 'GET', signal },
    options
  );
};

export const getApiBookingsIdtrackingPostBookingGetQueryKey = (id: string) => {
  return [`/bookings/${id}/tracking-post-booking`] as const;
};

export const getApiBookingsIdtrackingPostBookingGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiBookingsIdtrackingPostBookingGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>
  > = ({ signal }) =>
    apiBookingsIdtrackingPostBookingGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsIdtrackingPostBookingGetInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>>;
export type ApiBookingsIdtrackingPostBookingGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Booking resource.
 */
export const useApiBookingsIdtrackingPostBookingGetInfinite = <
  TData = Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiBookingsIdtrackingPostBookingGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiBookingsIdtrackingPostBookingGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiBookingsIdtrackingPostBookingGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>
  > = ({ signal }) =>
    apiBookingsIdtrackingPostBookingGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiBookingsIdtrackingPostBookingGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>
>;
export type ApiBookingsIdtrackingPostBookingGetQueryError = void;

/**
 * @summary Retrieves a Booking resource.
 */
export const useApiBookingsIdtrackingPostBookingGet = <
  TData = Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiBookingsIdtrackingPostBookingGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiBookingsIdtrackingPostBookingGetQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Compensation resource.
 * @summary Retrieves a Compensation resource.
 */
export const apiCompensationIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<CompensationJsonldReadCompensation>(
    { url: `/compensation/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiCompensationIdGetQueryKey = (id: string) => {
  return [`/compensation/${id}`] as const;
};

export const getApiCompensationIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCompensationIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCompensationIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCompensationIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCompensationIdGet>>
  > = ({ signal }) => apiCompensationIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCompensationIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCompensationIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCompensationIdGet>>
>;
export type ApiCompensationIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Compensation resource.
 */
export const useApiCompensationIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiCompensationIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCompensationIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCompensationIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCompensationIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCompensationIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCompensationIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCompensationIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCompensationIdGet>>
  > = ({ signal }) => apiCompensationIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiCompensationIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCompensationIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCompensationIdGet>>
>;
export type ApiCompensationIdGetQueryError = void;

/**
 * @summary Retrieves a Compensation resource.
 */
export const useApiCompensationIdGet = <
  TData = Awaited<ReturnType<typeof apiCompensationIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCompensationIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCompensationIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Config resource.
 * @summary Retrieves a Config resource.
 */
export const apiConfigGet = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ConfigJsonldReadConfig>(
    { url: `/config`, method: 'GET', signal },
    options
  );
};

export const getApiConfigGetQueryKey = () => {
  return [`/config`] as const;
};

export const getApiConfigGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiConfigGet>>,
  TError = void
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiConfigGet>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiConfigGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiConfigGet>>> = ({
    signal,
  }) => apiConfigGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiConfigGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiConfigGet>>
>;
export type ApiConfigGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Config resource.
 */
export const useApiConfigGetInfinite = <
  TData = Awaited<ReturnType<typeof apiConfigGet>>,
  TError = void
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiConfigGet>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiConfigGetInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiConfigGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiConfigGet>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiConfigGet>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiConfigGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiConfigGet>>> = ({
    signal,
  }) => apiConfigGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiConfigGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiConfigGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiConfigGet>>
>;
export type ApiConfigGetQueryError = void;

/**
 * @summary Retrieves a Config resource.
 */
export const useApiConfigGet = <
  TData = Awaited<ReturnType<typeof apiConfigGet>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiConfigGet>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiConfigGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of Country resources.
 * @summary Retrieves the collection of Country resources.
 */
export const apiCountriesGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiCountriesGetCollection200>(
    { url: `/countries`, method: 'GET', signal },
    options
  );
};

export const getApiCountriesGetCollectionQueryKey = () => {
  return [`/countries`] as const;
};

export const getApiCountriesGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCountriesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCountriesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>
  > = ({ signal }) => apiCountriesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCountriesGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCountriesGetCollection>>
>;
export type ApiCountriesGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of Country resources.
 */
export const useApiCountriesGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiCountriesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiCountriesGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCountriesGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCountriesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCountriesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>
  > = ({ signal }) => apiCountriesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCountriesGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCountriesGetCollection>>
>;
export type ApiCountriesGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of Country resources.
 */
export const useApiCountriesGetCollection = <
  TData = Awaited<ReturnType<typeof apiCountriesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiCountriesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCountriesGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Country resource.
 * @summary Retrieves a Country resource.
 */
export const apiCountriesIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<CountryJsonldReadCountry>(
    { url: `/countries/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiCountriesIdGetQueryKey = (id: string) => {
  return [`/countries/${id}`] as const;
};

export const getApiCountriesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCountriesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCountriesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiCountriesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCountriesIdGet>>
  > = ({ signal }) => apiCountriesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCountriesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCountriesIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCountriesIdGet>>
>;
export type ApiCountriesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Country resource.
 */
export const useApiCountriesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiCountriesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCountriesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCountriesIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCountriesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCountriesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCountriesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiCountriesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCountriesIdGet>>
  > = ({ signal }) => apiCountriesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiCountriesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCountriesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCountriesIdGet>>
>;
export type ApiCountriesIdGetQueryError = void;

/**
 * @summary Retrieves a Country resource.
 */
export const useApiCountriesIdGet = <
  TData = Awaited<ReturnType<typeof apiCountriesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCountriesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCountriesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of CustomerStep resources.
 * @summary Retrieves the collection of CustomerStep resources.
 */
export const apiCustomerStepsGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiCustomerStepsGetCollection200>(
    { url: `/customer_steps`, method: 'GET', signal },
    options
  );
};

export const getApiCustomerStepsGetCollectionQueryKey = () => {
  return [`/customer_steps`] as const;
};

export const getApiCustomerStepsGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCustomerStepsGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>
  > = ({ signal }) => apiCustomerStepsGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCustomerStepsGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>
>;
export type ApiCustomerStepsGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of CustomerStep resources.
 */
export const useApiCustomerStepsGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiCustomerStepsGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCustomerStepsGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCustomerStepsGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>
  > = ({ signal }) => apiCustomerStepsGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCustomerStepsGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>
>;
export type ApiCustomerStepsGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of CustomerStep resources.
 */
export const useApiCustomerStepsGetCollection = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCustomerStepsGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a CustomerStep resource.
 * @summary Retrieves a CustomerStep resource.
 */
export const apiCustomerStepsIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<CustomerStepJsonldReadCustomerStep>(
    { url: `/customer_steps/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiCustomerStepsIdGetQueryKey = (id: string) => {
  return [`/customer_steps/${id}`] as const;
};

export const getApiCustomerStepsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCustomerStepsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCustomerStepsIdGet>>
  > = ({ signal }) => apiCustomerStepsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCustomerStepsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomerStepsIdGet>>
>;
export type ApiCustomerStepsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a CustomerStep resource.
 */
export const useApiCustomerStepsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCustomerStepsIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCustomerStepsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiCustomerStepsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCustomerStepsIdGet>>
  > = ({ signal }) => apiCustomerStepsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCustomerStepsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomerStepsIdGet>>
>;
export type ApiCustomerStepsIdGetQueryError = void;

/**
 * @summary Retrieves a CustomerStep resource.
 */
export const useApiCustomerStepsIdGet = <
  TData = Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCustomerStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCustomerStepsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Customer resource.
 * @summary Retrieves a Customer resource.
 */
export const apiCustomersIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<CustomerJsonldReadCustomer>(
    { url: `/customers/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiCustomersIdGetQueryKey = (id: string) => {
  return [`/customers/${id}`] as const;
};

export const getApiCustomersIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCustomersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCustomersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiCustomersIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCustomersIdGet>>
  > = ({ signal }) => apiCustomersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiCustomersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCustomersIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomersIdGet>>
>;
export type ApiCustomersIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Customer resource.
 */
export const useApiCustomersIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiCustomersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiCustomersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCustomersIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiCustomersIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiCustomersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCustomersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiCustomersIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiCustomersIdGet>>
  > = ({ signal }) => apiCustomersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiCustomersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiCustomersIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomersIdGet>>
>;
export type ApiCustomersIdGetQueryError = void;

/**
 * @summary Retrieves a Customer resource.
 */
export const useApiCustomersIdGet = <
  TData = Awaited<ReturnType<typeof apiCustomersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiCustomersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiCustomersIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Updates the Customer resource.
 * @summary Updates the Customer resource.
 */
export const apiCustomersIdPatch = (
  id: string,
  customerCustomerInputDtoWriteCustomer: CustomerCustomerInputDtoWriteCustomer,
  options?: SecondParameter<typeof instance>
) => {
  return instance<CustomerJsonldReadCustomer>(
    {
      url: `/customers/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/merge-patch+json' },
      data: customerCustomerInputDtoWriteCustomer,
    },
    options
  );
};

export const getApiCustomersIdPatchMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiCustomersIdPatch>>,
    TError,
    { id: string; data: CustomerCustomerInputDtoWriteCustomer },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiCustomersIdPatch>>,
  TError,
  { id: string; data: CustomerCustomerInputDtoWriteCustomer },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiCustomersIdPatch>>,
    { id: string; data: CustomerCustomerInputDtoWriteCustomer }
  > = (props) => {
    const { id, data } = props ?? {};

    return apiCustomersIdPatch(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiCustomersIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiCustomersIdPatch>>
>;
export type ApiCustomersIdPatchMutationBody =
  CustomerCustomerInputDtoWriteCustomer;
export type ApiCustomersIdPatchMutationError = void;

/**
 * @summary Updates the Customer resource.
 */
export const useApiCustomersIdPatch = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiCustomersIdPatch>>,
    TError,
    { id: string; data: CustomerCustomerInputDtoWriteCustomer },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiCustomersIdPatch>>,
  TError,
  { id: string; data: CustomerCustomerInputDtoWriteCustomer },
  TContext
> => {
  const mutationOptions = getApiCustomersIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Each favorites list will contains all favorites of the list. If you want a paginated list use /favorites-list/{listId}/favorites
 * @summary Retrieve the favorites list for the current customer
 */
export const apiFavoritesListGetCollection = (
  params?: ApiFavoritesListGetCollectionParams,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiFavoritesListGetCollection200>(
    { url: `/favorites-list`, method: 'GET', params, signal },
    options
  );
};

export const getApiFavoritesListGetCollectionQueryKey = (
  params?: ApiFavoritesListGetCollectionParams
) => {
  return [`/favorites-list`, ...(params ? [params] : [])] as const;
};

export const getApiFavoritesListGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
  TError = unknown
>(
  params?: ApiFavoritesListGetCollectionParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiFavoritesListGetCollectionQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListGetCollection>>
  > = ({ signal, pageParam }) =>
    apiFavoritesListGetCollection(
      { ...params, page: pageParam || params?.['page'] },
      requestOptions,
      signal
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListGetCollection>>
>;
export type ApiFavoritesListGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieve the favorites list for the current customer
 */
export const useApiFavoritesListGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
  TError = unknown
>(
  params?: ApiFavoritesListGetCollectionParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListGetCollectionInfiniteQueryOptions(
    params,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
  TError = unknown
>(
  params?: ApiFavoritesListGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiFavoritesListGetCollectionQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListGetCollection>>
  > = ({ signal }) =>
    apiFavoritesListGetCollection(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListGetCollection>>
>;
export type ApiFavoritesListGetCollectionQueryError = unknown;

/**
 * @summary Retrieve the favorites list for the current customer
 */
export const useApiFavoritesListGetCollection = <
  TData = Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
  TError = unknown
>(
  params?: ApiFavoritesListGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListGetCollectionQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a RentalFavoriteList resource.
 * @summary Creates a RentalFavoriteList resource.
 */
export const apiFavoritesListPost = (
  apiFavoritesListPostBody:
    | RentalFavoriteListJsonldFavoriteListCreate
    | RentalFavoriteListFavoriteListCreate,
  options?: SecondParameter<typeof instance>
) => {
  return instance<RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental>(
    { url: `/favorites-list`, method: 'POST', data: apiFavoritesListPostBody },
    options
  );
};

export const getApiFavoritesListPostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListPost>>,
    TError,
    {
      data:
        | RentalFavoriteListJsonldFavoriteListCreate
        | RentalFavoriteListFavoriteListCreate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListPost>>,
  TError,
  {
    data:
      | RentalFavoriteListJsonldFavoriteListCreate
      | RentalFavoriteListFavoriteListCreate;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListPost>>,
    {
      data:
        | RentalFavoriteListJsonldFavoriteListCreate
        | RentalFavoriteListFavoriteListCreate;
    }
  > = (props) => {
    const { data } = props ?? {};

    return apiFavoritesListPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListPost>>
>;
export type ApiFavoritesListPostMutationBody =
  | RentalFavoriteListJsonldFavoriteListCreate
  | RentalFavoriteListFavoriteListCreate;
export type ApiFavoritesListPostMutationError = void;

/**
 * @summary Creates a RentalFavoriteList resource.
 */
export const useApiFavoritesListPost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListPost>>,
    TError,
    {
      data:
        | RentalFavoriteListJsonldFavoriteListCreate
        | RentalFavoriteListFavoriteListCreate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListPost>>,
  TError,
  {
    data:
      | RentalFavoriteListJsonldFavoriteListCreate
      | RentalFavoriteListFavoriteListCreate;
  },
  TContext
> => {
  const mutationOptions = getApiFavoritesListPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a RentalFavoriteList resource.
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const apiFavoritesListprivatePrivateIdGet = (
  privateId: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental>(
    { url: `/favorites-list/private/${privateId}`, method: 'GET', signal },
    options
  );
};

export const getApiFavoritesListprivatePrivateIdGetQueryKey = (
  privateId: string
) => {
  return [`/favorites-list/private/${privateId}`] as const;
};

export const getApiFavoritesListprivatePrivateIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
  TError = void
>(
  privateId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListprivatePrivateIdGetQueryKey(privateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>
  > = ({ signal }) =>
    apiFavoritesListprivatePrivateIdGet(privateId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!privateId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListprivatePrivateIdGetInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>>;
export type ApiFavoritesListprivatePrivateIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const useApiFavoritesListprivatePrivateIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
  TError = void
>(
  privateId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListprivatePrivateIdGetInfiniteQueryOptions(
      privateId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListprivatePrivateIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
  TError = void
>(
  privateId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListprivatePrivateIdGetQueryKey(privateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>
  > = ({ signal }) =>
    apiFavoritesListprivatePrivateIdGet(privateId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!privateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListprivatePrivateIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>
>;
export type ApiFavoritesListprivatePrivateIdGetQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const useApiFavoritesListprivatePrivateIdGet = <
  TData = Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
  TError = void
>(
  privateId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListprivatePrivateIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListprivatePrivateIdGetQueryOptions(
    privateId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a RentalFavoriteList resource.
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const apiFavoritesListpublicPublicIdGet = (
  publicId: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental>(
    { url: `/favorites-list/public/${publicId}`, method: 'GET', signal },
    options
  );
};

export const getApiFavoritesListpublicPublicIdGetQueryKey = (
  publicId: string
) => {
  return [`/favorites-list/public/${publicId}`] as const;
};

export const getApiFavoritesListpublicPublicIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
  TError = void
>(
  publicId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListpublicPublicIdGetQueryKey(publicId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>
  > = ({ signal }) =>
    apiFavoritesListpublicPublicIdGet(publicId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!publicId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListpublicPublicIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>
>;
export type ApiFavoritesListpublicPublicIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const useApiFavoritesListpublicPublicIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
  TError = void
>(
  publicId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListpublicPublicIdGetInfiniteQueryOptions(
    publicId,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListpublicPublicIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
  TError = void
>(
  publicId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListpublicPublicIdGetQueryKey(publicId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>
  > = ({ signal }) =>
    apiFavoritesListpublicPublicIdGet(publicId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!publicId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListpublicPublicIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>
>;
export type ApiFavoritesListpublicPublicIdGetQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const useApiFavoritesListpublicPublicIdGet = <
  TData = Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
  TError = void
>(
  publicId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListpublicPublicIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListpublicPublicIdGetQueryOptions(
    publicId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a RentalFavoriteList resource.
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const apiFavoritesListIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental>(
    { url: `/favorites-list/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiFavoritesListIdGetQueryKey = (id: string) => {
  return [`/favorites-list/${id}`] as const;
};

export const getApiFavoritesListIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiFavoritesListIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListIdGet>>
  > = ({ signal }) => apiFavoritesListIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListIdGet>>
>;
export type ApiFavoritesListIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const useApiFavoritesListIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiFavoritesListIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListIdGet>>
  > = ({ signal }) => apiFavoritesListIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListIdGet>>
>;
export type ApiFavoritesListIdGetQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteList resource.
 */
export const useApiFavoritesListIdGet = <
  TData = Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Replaces the RentalFavoriteList resource.
 * @summary Replaces the RentalFavoriteList resource.
 */
export const apiFavoritesListIdPut = (
  id: string,
  apiFavoritesListIdPutBody:
    | RentalFavoriteListJsonldFavoriteListUpdate
    | RentalFavoriteListFavoriteListUpdate,
  options?: SecondParameter<typeof instance>
) => {
  return instance<RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental>(
    {
      url: `/favorites-list/${id}`,
      method: 'PUT',
      data: apiFavoritesListIdPutBody,
    },
    options
  );
};

export const getApiFavoritesListIdPutMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListIdPut>>,
    TError,
    {
      id: string;
      data:
        | RentalFavoriteListJsonldFavoriteListUpdate
        | RentalFavoriteListFavoriteListUpdate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListIdPut>>,
  TError,
  {
    id: string;
    data:
      | RentalFavoriteListJsonldFavoriteListUpdate
      | RentalFavoriteListFavoriteListUpdate;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListIdPut>>,
    {
      id: string;
      data:
        | RentalFavoriteListJsonldFavoriteListUpdate
        | RentalFavoriteListFavoriteListUpdate;
    }
  > = (props) => {
    const { id, data } = props ?? {};

    return apiFavoritesListIdPut(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListIdPut>>
>;
export type ApiFavoritesListIdPutMutationBody =
  | RentalFavoriteListJsonldFavoriteListUpdate
  | RentalFavoriteListFavoriteListUpdate;
export type ApiFavoritesListIdPutMutationError = void;

/**
 * @summary Replaces the RentalFavoriteList resource.
 */
export const useApiFavoritesListIdPut = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListIdPut>>,
    TError,
    {
      id: string;
      data:
        | RentalFavoriteListJsonldFavoriteListUpdate
        | RentalFavoriteListFavoriteListUpdate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListIdPut>>,
  TError,
  {
    id: string;
    data:
      | RentalFavoriteListJsonldFavoriteListUpdate
      | RentalFavoriteListFavoriteListUpdate;
  },
  TContext
> => {
  const mutationOptions = getApiFavoritesListIdPutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Removes the RentalFavoriteList resource.
 * @summary Removes the RentalFavoriteList resource.
 */
export const apiFavoritesListIdDelete = (
  id: string,
  options?: SecondParameter<typeof instance>
) => {
  return instance<void>(
    { url: `/favorites-list/${id}`, method: 'DELETE' },
    options
  );
};

export const getApiFavoritesListIdDeleteMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListIdDelete>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListIdDelete>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListIdDelete>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return apiFavoritesListIdDelete(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListIdDelete>>
>;

export type ApiFavoritesListIdDeleteMutationError = void;

/**
 * @summary Removes the RentalFavoriteList resource.
 */
export const useApiFavoritesListIdDelete = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListIdDelete>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListIdDelete>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getApiFavoritesListIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves the collection of RentalFavorite resources.
 * @summary Retrieves the collection of RentalFavorite resources.
 */
export const apiFavoritesListListIdfavoritesGetCollection = (
  listId: string,
  params?: ApiFavoritesListListIdfavoritesGetCollectionParams,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiFavoritesListListIdfavoritesGetCollection200>(
    {
      url: `/favorites-list/${listId}/favorites`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getApiFavoritesListListIdfavoritesGetCollectionQueryKey = (
  listId: string,
  params?: ApiFavoritesListListIdfavoritesGetCollectionParams
) => {
  return [
    `/favorites-list/${listId}/favorites`,
    ...(params ? [params] : []),
  ] as const;
};

export const getApiFavoritesListListIdfavoritesGetCollectionInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>
    >,
    TError = unknown
  >(
    listId: string,
    params?: ApiFavoritesListListIdfavoritesGetCollectionParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiFavoritesListListIdfavoritesGetCollectionQueryKey(listId, params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>
    > = ({ signal, pageParam }) =>
      apiFavoritesListListIdfavoritesGetCollection(
        listId,
        { ...params, page: pageParam || params?.['page'] },
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!listId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiFavoritesListListIdfavoritesGetCollectionInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>
  >;
export type ApiFavoritesListListIdfavoritesGetCollectionInfiniteQueryError =
  unknown;

/**
 * @summary Retrieves the collection of RentalFavorite resources.
 */
export const useApiFavoritesListListIdfavoritesGetCollectionInfinite = <
  TData = Awaited<
    ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>
  >,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdfavoritesGetCollectionParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListListIdfavoritesGetCollectionInfiniteQueryOptions(
      listId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListListIdfavoritesGetCollectionQueryOptions = <
  TData = Awaited<
    ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>
  >,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdfavoritesGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdfavoritesGetCollectionQueryKey(listId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>
  > = ({ signal }) =>
    apiFavoritesListListIdfavoritesGetCollection(
      listId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!listId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdfavoritesGetCollectionQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>
  >;
export type ApiFavoritesListListIdfavoritesGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of RentalFavorite resources.
 */
export const useApiFavoritesListListIdfavoritesGetCollection = <
  TData = Awaited<
    ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>
  >,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdfavoritesGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListListIdfavoritesGetCollectionQueryOptions(
      listId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a RentalFavorite resource.
 * @summary Creates a RentalFavorite resource.
 */
export const apiFavoritesListListIdfavoritesPost = (
  listId: string,
  apiFavoritesListListIdfavoritesPostBody:
    | RentalFavoriteJsonldFavoriteCreate
    | RentalFavoriteFavoriteCreate,
  options?: SecondParameter<typeof instance>
) => {
  return instance<RentalFavoriteJsonldFavoriteGet>(
    {
      url: `/favorites-list/${listId}/favorites`,
      method: 'POST',
      data: apiFavoritesListListIdfavoritesPostBody,
    },
    options
  );
};

export const getApiFavoritesListListIdfavoritesPostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesPost>>,
    TError,
    {
      listId: string;
      data: RentalFavoriteJsonldFavoriteCreate | RentalFavoriteFavoriteCreate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesPost>>,
  TError,
  {
    listId: string;
    data: RentalFavoriteJsonldFavoriteCreate | RentalFavoriteFavoriteCreate;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesPost>>,
    {
      listId: string;
      data: RentalFavoriteJsonldFavoriteCreate | RentalFavoriteFavoriteCreate;
    }
  > = (props) => {
    const { listId, data } = props ?? {};

    return apiFavoritesListListIdfavoritesPost(listId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListListIdfavoritesPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesPost>>
>;
export type ApiFavoritesListListIdfavoritesPostMutationBody =
  | RentalFavoriteJsonldFavoriteCreate
  | RentalFavoriteFavoriteCreate;
export type ApiFavoritesListListIdfavoritesPostMutationError = void;

/**
 * @summary Creates a RentalFavorite resource.
 */
export const useApiFavoritesListListIdfavoritesPost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesPost>>,
    TError,
    {
      listId: string;
      data: RentalFavoriteJsonldFavoriteCreate | RentalFavoriteFavoriteCreate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesPost>>,
  TError,
  {
    listId: string;
    data: RentalFavoriteJsonldFavoriteCreate | RentalFavoriteFavoriteCreate;
  },
  TContext
> => {
  const mutationOptions =
    getApiFavoritesListListIdfavoritesPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a RentalFavorite resource.
 * @summary Retrieves a RentalFavorite resource.
 */
export const apiFavoritesListListIdfavoritesIdGet = (
  listId: string,
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalFavoriteJsonldFavoriteGet>(
    { url: `/favorites-list/${listId}/favorites/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiFavoritesListListIdfavoritesIdGetQueryKey = (
  listId: string,
  id: string
) => {
  return [`/favorites-list/${listId}/favorites/${id}`] as const;
};

export const getApiFavoritesListListIdfavoritesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdfavoritesIdGetQueryKey(listId, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>
  > = ({ signal }) =>
    apiFavoritesListListIdfavoritesIdGet(listId, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(listId && id),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdfavoritesIdGetInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>>;
export type ApiFavoritesListListIdfavoritesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a RentalFavorite resource.
 */
export const useApiFavoritesListListIdfavoritesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListListIdfavoritesIdGetInfiniteQueryOptions(
      listId,
      id,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListListIdfavoritesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdfavoritesIdGetQueryKey(listId, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>
  > = ({ signal }) =>
    apiFavoritesListListIdfavoritesIdGet(listId, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(listId && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdfavoritesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>
>;
export type ApiFavoritesListListIdfavoritesIdGetQueryError = void;

/**
 * @summary Retrieves a RentalFavorite resource.
 */
export const useApiFavoritesListListIdfavoritesIdGet = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListListIdfavoritesIdGetQueryOptions(
    listId,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Removes the RentalFavorite resource.
 * @summary Removes the RentalFavorite resource.
 */
export const apiFavoritesListListIdfavoritesIdDelete = (
  listId: string,
  id: string,
  options?: SecondParameter<typeof instance>
) => {
  return instance<void>(
    { url: `/favorites-list/${listId}/favorites/${id}`, method: 'DELETE' },
    options
  );
};

export const getApiFavoritesListListIdfavoritesIdDeleteMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdDelete>>,
    TError,
    { listId: string; id: string },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdDelete>>,
  TError,
  { listId: string; id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdDelete>>,
    { listId: string; id: string }
  > = (props) => {
    const { listId, id } = props ?? {};

    return apiFavoritesListListIdfavoritesIdDelete(listId, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListListIdfavoritesIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdDelete>>
>;

export type ApiFavoritesListListIdfavoritesIdDeleteMutationError = void;

/**
 * @summary Removes the RentalFavorite resource.
 */
export const useApiFavoritesListListIdfavoritesIdDelete = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdDelete>>,
    TError,
    { listId: string; id: string },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListListIdfavoritesIdDelete>>,
  TError,
  { listId: string; id: string },
  TContext
> => {
  const mutationOptions =
    getApiFavoritesListListIdfavoritesIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves the collection of RentalFavoriteUser resources.
 * @summary Retrieves the collection of RentalFavoriteUser resources.
 */
export const apiFavoritesListListIdusersGetCollection = (
  listId: string,
  params?: ApiFavoritesListListIdusersGetCollectionParams,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiFavoritesListListIdusersGetCollection200>(
    { url: `/favorites-list/${listId}/users`, method: 'GET', params, signal },
    options
  );
};

export const getApiFavoritesListListIdusersGetCollectionQueryKey = (
  listId: string,
  params?: ApiFavoritesListListIdusersGetCollectionParams
) => {
  return [
    `/favorites-list/${listId}/users`,
    ...(params ? [params] : []),
  ] as const;
};

export const getApiFavoritesListListIdusersGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdusersGetCollectionParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdusersGetCollectionQueryKey(listId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>
  > = ({ signal, pageParam }) =>
    apiFavoritesListListIdusersGetCollection(
      listId,
      { ...params, page: pageParam || params?.['page'] },
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!listId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdusersGetCollectionInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>
  >;
export type ApiFavoritesListListIdusersGetCollectionInfiniteQueryError =
  unknown;

/**
 * @summary Retrieves the collection of RentalFavoriteUser resources.
 */
export const useApiFavoritesListListIdusersGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdusersGetCollectionParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListListIdusersGetCollectionInfiniteQueryOptions(
      listId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListListIdusersGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdusersGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdusersGetCollectionQueryKey(listId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>
  > = ({ signal }) =>
    apiFavoritesListListIdusersGetCollection(
      listId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!listId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdusersGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>
>;
export type ApiFavoritesListListIdusersGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of RentalFavoriteUser resources.
 */
export const useApiFavoritesListListIdusersGetCollection = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
  TError = unknown
>(
  listId: string,
  params?: ApiFavoritesListListIdusersGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListListIdusersGetCollectionQueryOptions(
    listId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a RentalFavoriteUser resource.
 * @summary Creates a RentalFavoriteUser resource.
 */
export const apiFavoritesListListIdusersPost = (
  listId: string,
  apiFavoritesListListIdusersPostBody:
    | RentalFavoriteUserJsonldFavoriteUserCreate
    | RentalFavoriteUserFavoriteUserCreate,
  options?: SecondParameter<typeof instance>
) => {
  return instance<RentalFavoriteUserJsonldFavoriteUserGet>(
    {
      url: `/favorites-list/${listId}/users`,
      method: 'POST',
      data: apiFavoritesListListIdusersPostBody,
    },
    options
  );
};

export const getApiFavoritesListListIdusersPostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersPost>>,
    TError,
    {
      listId: string;
      data:
        | RentalFavoriteUserJsonldFavoriteUserCreate
        | RentalFavoriteUserFavoriteUserCreate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersPost>>,
  TError,
  {
    listId: string;
    data:
      | RentalFavoriteUserJsonldFavoriteUserCreate
      | RentalFavoriteUserFavoriteUserCreate;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersPost>>,
    {
      listId: string;
      data:
        | RentalFavoriteUserJsonldFavoriteUserCreate
        | RentalFavoriteUserFavoriteUserCreate;
    }
  > = (props) => {
    const { listId, data } = props ?? {};

    return apiFavoritesListListIdusersPost(listId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListListIdusersPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersPost>>
>;
export type ApiFavoritesListListIdusersPostMutationBody =
  | RentalFavoriteUserJsonldFavoriteUserCreate
  | RentalFavoriteUserFavoriteUserCreate;
export type ApiFavoritesListListIdusersPostMutationError = void;

/**
 * @summary Creates a RentalFavoriteUser resource.
 */
export const useApiFavoritesListListIdusersPost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersPost>>,
    TError,
    {
      listId: string;
      data:
        | RentalFavoriteUserJsonldFavoriteUserCreate
        | RentalFavoriteUserFavoriteUserCreate;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersPost>>,
  TError,
  {
    listId: string;
    data:
      | RentalFavoriteUserJsonldFavoriteUserCreate
      | RentalFavoriteUserFavoriteUserCreate;
  },
  TContext
> => {
  const mutationOptions =
    getApiFavoritesListListIdusersPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a RentalFavoriteUser resource.
 * @summary Retrieves a RentalFavoriteUser resource.
 */
export const apiFavoritesListListIdusersIdGet = (
  listId: string,
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalFavoriteUserJsonldFavoriteUserGet>(
    { url: `/favorites-list/${listId}/users/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiFavoritesListListIdusersIdGetQueryKey = (
  listId: string,
  id: string
) => {
  return [`/favorites-list/${listId}/users/${id}`] as const;
};

export const getApiFavoritesListListIdusersIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdusersIdGetQueryKey(listId, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>
  > = ({ signal }) =>
    apiFavoritesListListIdusersIdGet(listId, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(listId && id),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdusersIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>
>;
export type ApiFavoritesListListIdusersIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteUser resource.
 */
export const useApiFavoritesListListIdusersIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListListIdusersIdGetInfiniteQueryOptions(
    listId,
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListListIdusersIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFavoritesListListIdusersIdGetQueryKey(listId, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>
  > = ({ signal }) =>
    apiFavoritesListListIdusersIdGet(listId, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(listId && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFavoritesListListIdusersIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>
>;
export type ApiFavoritesListListIdusersIdGetQueryError = void;

/**
 * @summary Retrieves a RentalFavoriteUser resource.
 */
export const useApiFavoritesListListIdusersIdGet = <
  TData = Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
  TError = void
>(
  listId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFavoritesListListIdusersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFavoritesListListIdusersIdGetQueryOptions(
    listId,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Removes the RentalFavoriteUser resource.
 * @summary Removes the RentalFavoriteUser resource.
 */
export const apiFavoritesListListIdusersIdDelete = (
  listId: string,
  id: string,
  options?: SecondParameter<typeof instance>
) => {
  return instance<void>(
    { url: `/favorites-list/${listId}/users/${id}`, method: 'DELETE' },
    options
  );
};

export const getApiFavoritesListListIdusersIdDeleteMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdDelete>>,
    TError,
    { listId: string; id: string },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersIdDelete>>,
  TError,
  { listId: string; id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdDelete>>,
    { listId: string; id: string }
  > = (props) => {
    const { listId, id } = props ?? {};

    return apiFavoritesListListIdusersIdDelete(listId, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFavoritesListListIdusersIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersIdDelete>>
>;

export type ApiFavoritesListListIdusersIdDeleteMutationError = void;

/**
 * @summary Removes the RentalFavoriteUser resource.
 */
export const useApiFavoritesListListIdusersIdDelete = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFavoritesListListIdusersIdDelete>>,
    TError,
    { listId: string; id: string },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFavoritesListListIdusersIdDelete>>,
  TError,
  { listId: string; id: string },
  TContext
> => {
  const mutationOptions =
    getApiFavoritesListListIdusersIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves the collection of RentalFavorite resources.
 * @summary Retrieves the collection of RentalFavorite resources.
 */
export const apiFavoritesListPublicIdfavoritespublicGetCollection = (
  publicId: string,
  params?: ApiFavoritesListPublicIdfavoritespublicGetCollectionParams,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiFavoritesListPublicIdfavoritespublicGetCollection200>(
    {
      url: `/favorites-list/${publicId}/favorites/public`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getApiFavoritesListPublicIdfavoritespublicGetCollectionQueryKey = (
  publicId: string,
  params?: ApiFavoritesListPublicIdfavoritespublicGetCollectionParams
) => {
  return [
    `/favorites-list/${publicId}/favorites/public`,
    ...(params ? [params] : []),
  ] as const;
};

export const getApiFavoritesListPublicIdfavoritespublicGetCollectionInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
    >,
    TError = unknown
  >(
    publicId: string,
    params?: ApiFavoritesListPublicIdfavoritespublicGetCollectionParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            typeof apiFavoritesListPublicIdfavoritespublicGetCollection
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiFavoritesListPublicIdfavoritespublicGetCollectionQueryKey(
        publicId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
      >
    > = ({ signal, pageParam }) =>
      apiFavoritesListPublicIdfavoritespublicGetCollection(
        publicId,
        { ...params, page: pageParam || params?.['page'] },
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!publicId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiFavoritesListPublicIdfavoritespublicGetCollectionInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
    >
  >;
export type ApiFavoritesListPublicIdfavoritespublicGetCollectionInfiniteQueryError =
  unknown;

/**
 * @summary Retrieves the collection of RentalFavorite resources.
 */
export const useApiFavoritesListPublicIdfavoritespublicGetCollectionInfinite = <
  TData = Awaited<
    ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
  >,
  TError = unknown
>(
  publicId: string,
  params?: ApiFavoritesListPublicIdfavoritespublicGetCollectionParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListPublicIdfavoritespublicGetCollectionInfiniteQueryOptions(
      publicId,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFavoritesListPublicIdfavoritespublicGetCollectionQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
    >,
    TError = unknown
  >(
    publicId: string,
    params?: ApiFavoritesListPublicIdfavoritespublicGetCollectionParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof apiFavoritesListPublicIdfavoritespublicGetCollection
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiFavoritesListPublicIdfavoritespublicGetCollectionQueryKey(
        publicId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
      >
    > = ({ signal }) =>
      apiFavoritesListPublicIdfavoritespublicGetCollection(
        publicId,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!publicId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiFavoritesListPublicIdfavoritespublicGetCollectionQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
    >
  >;
export type ApiFavoritesListPublicIdfavoritespublicGetCollectionQueryError =
  unknown;

/**
 * @summary Retrieves the collection of RentalFavorite resources.
 */
export const useApiFavoritesListPublicIdfavoritespublicGetCollection = <
  TData = Awaited<
    ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
  >,
  TError = unknown
>(
  publicId: string,
  params?: ApiFavoritesListPublicIdfavoritespublicGetCollectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof apiFavoritesListPublicIdfavoritespublicGetCollection>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiFavoritesListPublicIdfavoritespublicGetCollectionQueryOptions(
      publicId,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a Filestorage resource.
 * @summary Creates a Filestorage resource.
 */
export const apiFilestoragefilePost = (
  apiFilestoragefilePostBody: ApiFilestoragefilePostBody,
  options?: SecondParameter<typeof instance>
) => {
  const formData = new FormData();
  if (apiFilestoragefilePostBody.file !== undefined) {
    formData.append('file', apiFilestoragefilePostBody.file);
  }

  return instance<FilestorageJsonldReadFile>(
    {
      url: `/filestorage/file`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getApiFilestoragefilePostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFilestoragefilePost>>,
    TError,
    { data: ApiFilestoragefilePostBody },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiFilestoragefilePost>>,
  TError,
  { data: ApiFilestoragefilePostBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiFilestoragefilePost>>,
    { data: ApiFilestoragefilePostBody }
  > = (props) => {
    const { data } = props ?? {};

    return apiFilestoragefilePost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiFilestoragefilePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiFilestoragefilePost>>
>;
export type ApiFilestoragefilePostMutationBody = ApiFilestoragefilePostBody;
export type ApiFilestoragefilePostMutationError = void;

/**
 * @summary Creates a Filestorage resource.
 */
export const useApiFilestoragefilePost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiFilestoragefilePost>>,
    TError,
    { data: ApiFilestoragefilePostBody },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiFilestoragefilePost>>,
  TError,
  { data: ApiFilestoragefilePostBody },
  TContext
> => {
  const mutationOptions = getApiFilestoragefilePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a Filestorage resource.
 * @summary Retrieves a Filestorage resource.
 */
export const apiFilestoragefileFilenameGet = (
  filename: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<FilestorageJsonldReadFile>(
    { url: `/filestorage/file/${filename}`, method: 'GET', signal },
    options
  );
};

export const getApiFilestoragefileFilenameGetQueryKey = (filename: string) => {
  return [`/filestorage/file/${filename}`] as const;
};

export const getApiFilestoragefileFilenameGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
  TError = void
>(
  filename: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFilestoragefileFilenameGetQueryKey(filename);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>
  > = ({ signal }) =>
    apiFilestoragefileFilenameGet(filename, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!filename,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFilestoragefileFilenameGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>
>;
export type ApiFilestoragefileFilenameGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Filestorage resource.
 */
export const useApiFilestoragefileFilenameGetInfinite = <
  TData = Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
  TError = void
>(
  filename: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFilestoragefileFilenameGetInfiniteQueryOptions(
    filename,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiFilestoragefileFilenameGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
  TError = void
>(
  filename: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiFilestoragefileFilenameGetQueryKey(filename);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>
  > = ({ signal }) =>
    apiFilestoragefileFilenameGet(filename, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!filename,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiFilestoragefileFilenameGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>
>;
export type ApiFilestoragefileFilenameGetQueryError = void;

/**
 * @summary Retrieves a Filestorage resource.
 */
export const useApiFilestoragefileFilenameGet = <
  TData = Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
  TError = void
>(
  filename: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiFilestoragefileFilenameGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiFilestoragefileFilenameGetQueryOptions(
    filename,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of Locality resources.
 * @summary Retrieves the collection of Locality resources.
 */
export const apiLocalitiesGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiLocalitiesGetCollection200>(
    { url: `/localities`, method: 'GET', signal },
    options
  );
};

export const getApiLocalitiesGetCollectionQueryKey = () => {
  return [`/localities`] as const;
};

export const getApiLocalitiesGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiLocalitiesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>
  > = ({ signal }) => apiLocalitiesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiLocalitiesGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiLocalitiesGetCollection>>
>;
export type ApiLocalitiesGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of Locality resources.
 */
export const useApiLocalitiesGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiLocalitiesGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiLocalitiesGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiLocalitiesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>
  > = ({ signal }) => apiLocalitiesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiLocalitiesGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiLocalitiesGetCollection>>
>;
export type ApiLocalitiesGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of Locality resources.
 */
export const useApiLocalitiesGetCollection = <
  TData = Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiLocalitiesGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Locality resource.
 * @summary Retrieves a Locality resource.
 */
export const apiLocalitiesIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<LocalityJsonldReadLocality>(
    { url: `/localities/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiLocalitiesIdGetQueryKey = (id: string) => {
  return [`/localities/${id}`] as const;
};

export const getApiLocalitiesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiLocalitiesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiLocalitiesIdGet>>
  > = ({ signal }) => apiLocalitiesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiLocalitiesIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiLocalitiesIdGet>>
>;
export type ApiLocalitiesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Locality resource.
 */
export const useApiLocalitiesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiLocalitiesIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiLocalitiesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiLocalitiesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiLocalitiesIdGet>>
  > = ({ signal }) => apiLocalitiesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiLocalitiesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiLocalitiesIdGet>>
>;
export type ApiLocalitiesIdGetQueryError = void;

/**
 * @summary Retrieves a Locality resource.
 */
export const useApiLocalitiesIdGet = <
  TData = Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiLocalitiesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiLocalitiesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Owner resource.
 * @summary Retrieves a Owner resource.
 */
export const apiOwnersIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<OwnerJsonldReadOwner>(
    { url: `/owners/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiOwnersIdGetQueryKey = (id: string) => {
  return [`/owners/${id}`] as const;
};

export const getApiOwnersIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiOwnersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiOwnersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiOwnersIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiOwnersIdGet>>> = ({
    signal,
  }) => apiOwnersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiOwnersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiOwnersIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiOwnersIdGet>>
>;
export type ApiOwnersIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Owner resource.
 */
export const useApiOwnersIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiOwnersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiOwnersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiOwnersIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiOwnersIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiOwnersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiOwnersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiOwnersIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiOwnersIdGet>>> = ({
    signal,
  }) => apiOwnersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiOwnersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiOwnersIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiOwnersIdGet>>
>;
export type ApiOwnersIdGetQueryError = void;

/**
 * @summary Retrieves a Owner resource.
 */
export const useApiOwnersIdGet = <
  TData = Awaited<ReturnType<typeof apiOwnersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiOwnersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiOwnersIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of PaymentStep resources.
 * @summary Retrieves the collection of PaymentStep resources.
 */
export const apiPaymentStepsGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiPaymentStepsGetCollection200>(
    { url: `/payment_steps`, method: 'GET', signal },
    options
  );
};

export const getApiPaymentStepsGetCollectionQueryKey = () => {
  return [`/payment_steps`] as const;
};

export const getApiPaymentStepsGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiPaymentStepsGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>
  > = ({ signal }) => apiPaymentStepsGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiPaymentStepsGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>
>;
export type ApiPaymentStepsGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of PaymentStep resources.
 */
export const useApiPaymentStepsGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiPaymentStepsGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPaymentStepsGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiPaymentStepsGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>
  > = ({ signal }) => apiPaymentStepsGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiPaymentStepsGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>
>;
export type ApiPaymentStepsGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of PaymentStep resources.
 */
export const useApiPaymentStepsGetCollection = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiPaymentStepsGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a PaymentStep resource.
 * @summary Retrieves a PaymentStep resource.
 */
export const apiPaymentStepsIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<PaymentStepJsonldReadPaymentStep>(
    { url: `/payment_steps/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiPaymentStepsIdGetQueryKey = (id: string) => {
  return [`/payment_steps/${id}`] as const;
};

export const getApiPaymentStepsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiPaymentStepsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiPaymentStepsIdGet>>
  > = ({ signal }) => apiPaymentStepsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiPaymentStepsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiPaymentStepsIdGet>>
>;
export type ApiPaymentStepsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a PaymentStep resource.
 */
export const useApiPaymentStepsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiPaymentStepsIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPaymentStepsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiPaymentStepsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiPaymentStepsIdGet>>
  > = ({ signal }) => apiPaymentStepsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiPaymentStepsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiPaymentStepsIdGet>>
>;
export type ApiPaymentStepsIdGetQueryError = void;

/**
 * @summary Retrieves a PaymentStep resource.
 */
export const useApiPaymentStepsIdGet = <
  TData = Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiPaymentStepsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiPaymentStepsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Updates the PaymentStep resource.
 * @summary Updates the PaymentStep resource.
 */
export const apiPaymentStepsIdPatch = (
  id: string,
  paymentStepWritePaymentStep: PaymentStepWritePaymentStep,
  options?: SecondParameter<typeof instance>
) => {
  return instance<PaymentStepJsonldReadPaymentStep>(
    {
      url: `/payment_steps/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/merge-patch+json' },
      data: paymentStepWritePaymentStep,
    },
    options
  );
};

export const getApiPaymentStepsIdPatchMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiPaymentStepsIdPatch>>,
    TError,
    { id: string; data: PaymentStepWritePaymentStep },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiPaymentStepsIdPatch>>,
  TError,
  { id: string; data: PaymentStepWritePaymentStep },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiPaymentStepsIdPatch>>,
    { id: string; data: PaymentStepWritePaymentStep }
  > = (props) => {
    const { id, data } = props ?? {};

    return apiPaymentStepsIdPatch(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiPaymentStepsIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiPaymentStepsIdPatch>>
>;
export type ApiPaymentStepsIdPatchMutationBody = PaymentStepWritePaymentStep;
export type ApiPaymentStepsIdPatchMutationError = void;

/**
 * @summary Updates the PaymentStep resource.
 */
export const useApiPaymentStepsIdPatch = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiPaymentStepsIdPatch>>,
    TError,
    { id: string; data: PaymentStepWritePaymentStep },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiPaymentStepsIdPatch>>,
  TError,
  { id: string; data: PaymentStepWritePaymentStep },
  TContext
> => {
  const mutationOptions = getApiPaymentStepsIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a PromotionalCodeUsed resource.
 * @summary Retrieves a PromotionalCodeUsed resource.
 */
export const apiPromotionalCodesUsedIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<PromotionalCodeUsedJsonldReadPromotionalCodeUsed>(
    { url: `/promotional_codes_used/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiPromotionalCodesUsedIdGetQueryKey = (id: string) => {
  return [`/promotional_codes_used/${id}`] as const;
};

export const getApiPromotionalCodesUsedIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiPromotionalCodesUsedIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>
  > = ({ signal }) => apiPromotionalCodesUsedIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiPromotionalCodesUsedIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>
>;
export type ApiPromotionalCodesUsedIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a PromotionalCodeUsed resource.
 */
export const useApiPromotionalCodesUsedIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiPromotionalCodesUsedIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiPromotionalCodesUsedIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiPromotionalCodesUsedIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>
  > = ({ signal }) => apiPromotionalCodesUsedIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiPromotionalCodesUsedIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>
>;
export type ApiPromotionalCodesUsedIdGetQueryError = void;

/**
 * @summary Retrieves a PromotionalCodeUsed resource.
 */
export const useApiPromotionalCodesUsedIdGet = <
  TData = Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiPromotionalCodesUsedIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiPromotionalCodesUsedIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a RentalStayAvailable resource.
 * @summary Retrieves a RentalStayAvailable resource.
 */
export const apiRentalStaysAvailableRentalIdStartDateEndDateGet = (
  rentalId: number,
  startDate: string,
  endDate: string,
  params?: ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalStayAvailableJsonldReadRentalStayAvailable>(
    {
      url: `/rental_stays_available/${rentalId}/${startDate}/${endDate}/`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryKey = (
  rentalId: number,
  startDate: string,
  endDate: string,
  params?: ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams
) => {
  return [
    `/rental_stays_available/${rentalId}/${startDate}/${endDate}/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getApiRentalStaysAvailableRentalIdStartDateEndDateGetInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
    >,
    TError = void
  >(
    rentalId: number,
    startDate: string,
    endDate: string,
    params?: ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryKey(
        rentalId,
        startDate,
        endDate,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
      >
    > = ({ signal }) =>
      apiRentalStaysAvailableRentalIdStartDateEndDateGet(
        rentalId,
        startDate,
        endDate,
        { ...params },
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(rentalId && startDate && endDate),
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiRentalStaysAvailableRentalIdStartDateEndDateGetInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
    >
  >;
export type ApiRentalStaysAvailableRentalIdStartDateEndDateGetInfiniteQueryError =
  void;

/**
 * @summary Retrieves a RentalStayAvailable resource.
 */
export const useApiRentalStaysAvailableRentalIdStartDateEndDateGetInfinite = <
  TData = Awaited<
    ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
  >,
  TError = void
>(
  rentalId: number,
  startDate: string,
  endDate: string,
  params?: ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiRentalStaysAvailableRentalIdStartDateEndDateGetInfiniteQueryOptions(
      rentalId,
      startDate,
      endDate,
      params,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
    >,
    TError = void
  >(
    rentalId: number,
    startDate: string,
    endDate: string,
    params?: ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryKey(
        rentalId,
        startDate,
        endDate,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
      >
    > = ({ signal }) =>
      apiRentalStaysAvailableRentalIdStartDateEndDateGet(
        rentalId,
        startDate,
        endDate,
        params,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(rentalId && startDate && endDate),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
    >
  >;
export type ApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryError = void;

/**
 * @summary Retrieves a RentalStayAvailable resource.
 */
export const useApiRentalStaysAvailableRentalIdStartDateEndDateGet = <
  TData = Awaited<
    ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
  >,
  TError = void
>(
  rentalId: number,
  startDate: string,
  endDate: string,
  params?: ApiRentalStaysAvailableRentalIdStartDateEndDateGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof apiRentalStaysAvailableRentalIdStartDateEndDateGet>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiRentalStaysAvailableRentalIdStartDateEndDateGetQueryOptions(
      rentalId,
      startDate,
      endDate,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Rental resource.
 * @summary Retrieve a rental from a Reference AE
 */
export const apiRentalsreferenceAeReferenceAeGet = (
  referenceAe: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalJsonldReadRental>(
    { url: `/rentals/reference_ae/${referenceAe}`, method: 'GET', signal },
    options
  );
};

export const getApiRentalsreferenceAeReferenceAeGetQueryKey = (
  referenceAe: string
) => {
  return [`/rentals/reference_ae/${referenceAe}`] as const;
};

export const getApiRentalsreferenceAeReferenceAeGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
  TError = void
>(
  referenceAe: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiRentalsreferenceAeReferenceAeGetQueryKey(referenceAe);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>
  > = ({ signal }) =>
    apiRentalsreferenceAeReferenceAeGet(referenceAe, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!referenceAe,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiRentalsreferenceAeReferenceAeGetInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>>;
export type ApiRentalsreferenceAeReferenceAeGetInfiniteQueryError = void;

/**
 * @summary Retrieve a rental from a Reference AE
 */
export const useApiRentalsreferenceAeReferenceAeGetInfinite = <
  TData = Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
  TError = void
>(
  referenceAe: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiRentalsreferenceAeReferenceAeGetInfiniteQueryOptions(
      referenceAe,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiRentalsreferenceAeReferenceAeGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
  TError = void
>(
  referenceAe: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiRentalsreferenceAeReferenceAeGetQueryKey(referenceAe);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>
  > = ({ signal }) =>
    apiRentalsreferenceAeReferenceAeGet(referenceAe, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!referenceAe,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiRentalsreferenceAeReferenceAeGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>
>;
export type ApiRentalsreferenceAeReferenceAeGetQueryError = void;

/**
 * @summary Retrieve a rental from a Reference AE
 */
export const useApiRentalsreferenceAeReferenceAeGet = <
  TData = Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
  TError = void
>(
  referenceAe: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiRentalsreferenceAeReferenceAeGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiRentalsreferenceAeReferenceAeGetQueryOptions(
    referenceAe,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Rental resource.
 * @summary Retrieves a Rental resource.
 */
export const apiRentalsIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<RentalJsonldReadRental>(
    { url: `/rentals/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiRentalsIdGetQueryKey = (id: string) => {
  return [`/rentals/${id}`] as const;
};

export const getApiRentalsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiRentalsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiRentalsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiRentalsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiRentalsIdGet>>> = ({
    signal,
  }) => apiRentalsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiRentalsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiRentalsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiRentalsIdGet>>
>;
export type ApiRentalsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Rental resource.
 */
export const useApiRentalsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiRentalsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiRentalsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiRentalsIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiRentalsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiRentalsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiRentalsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiRentalsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiRentalsIdGet>>> = ({
    signal,
  }) => apiRentalsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiRentalsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiRentalsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiRentalsIdGet>>
>;
export type ApiRentalsIdGetQueryError = void;

/**
 * @summary Retrieves a Rental resource.
 */
export const useApiRentalsIdGet = <
  TData = Awaited<ReturnType<typeof apiRentalsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiRentalsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiRentalsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of RentalBadge resources.
 * @summary Retrieve the badges of rental
 */
export const apiRentalsRentalIdbadgesGetCollection = (
  rentalId: number,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiRentalsRentalIdbadgesGetCollection200>(
    { url: `/rentals/${rentalId}/badges`, method: 'GET', signal },
    options
  );
};

export const getApiRentalsRentalIdbadgesGetCollectionQueryKey = (
  rentalId: number
) => {
  return [`/rentals/${rentalId}/badges`] as const;
};

export const getApiRentalsRentalIdbadgesGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
  TError = unknown
>(
  rentalId: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiRentalsRentalIdbadgesGetCollectionQueryKey(rentalId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>
  > = ({ signal }) =>
    apiRentalsRentalIdbadgesGetCollection(rentalId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!rentalId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiRentalsRentalIdbadgesGetCollectionInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>
  >;
export type ApiRentalsRentalIdbadgesGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieve the badges of rental
 */
export const useApiRentalsRentalIdbadgesGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
  TError = unknown
>(
  rentalId: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiRentalsRentalIdbadgesGetCollectionInfiniteQueryOptions(
      rentalId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiRentalsRentalIdbadgesGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
  TError = unknown
>(
  rentalId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getApiRentalsRentalIdbadgesGetCollectionQueryKey(rentalId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>
  > = ({ signal }) =>
    apiRentalsRentalIdbadgesGetCollection(rentalId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!rentalId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiRentalsRentalIdbadgesGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>
>;
export type ApiRentalsRentalIdbadgesGetCollectionQueryError = unknown;

/**
 * @summary Retrieve the badges of rental
 */
export const useApiRentalsRentalIdbadgesGetCollection = <
  TData = Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
  TError = unknown
>(
  rentalId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiRentalsRentalIdbadgesGetCollection>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiRentalsRentalIdbadgesGetCollectionQueryOptions(
    rentalId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Review resource.
 * @summary Retrieves a Review resource.
 */
export const apiReviewsIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ReviewJsonldReadReview>(
    { url: `/reviews/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiReviewsIdGetQueryKey = (id: string) => {
  return [`/reviews/${id}`] as const;
};

export const getApiReviewsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiReviewsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiReviewsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiReviewsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiReviewsIdGet>>> = ({
    signal,
  }) => apiReviewsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiReviewsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiReviewsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiReviewsIdGet>>
>;
export type ApiReviewsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Review resource.
 */
export const useApiReviewsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiReviewsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiReviewsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiReviewsIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiReviewsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiReviewsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiReviewsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiReviewsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiReviewsIdGet>>> = ({
    signal,
  }) => apiReviewsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiReviewsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiReviewsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiReviewsIdGet>>
>;
export type ApiReviewsIdGetQueryError = void;

/**
 * @summary Retrieves a Review resource.
 */
export const useApiReviewsIdGet = <
  TData = Awaited<ReturnType<typeof apiReviewsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiReviewsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiReviewsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of SatisfactionSurvey resources.
 * @summary Retrieves the collection of SatisfactionSurvey resources.
 */
export const apiSatisfactionSurveysrentalRentalIdGetCollection = (
  rentalId: number,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiSatisfactionSurveysrentalRentalIdGetCollection200>(
    { url: `/satisfaction_surveys/rental/${rentalId}/`, method: 'GET', signal },
    options
  );
};

export const getApiSatisfactionSurveysrentalRentalIdGetCollectionQueryKey = (
  rentalId: number
) => {
  return [`/satisfaction_surveys/rental/${rentalId}/`] as const;
};

export const getApiSatisfactionSurveysrentalRentalIdGetCollectionInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
    >,
    TError = unknown
  >(
    rentalId: number,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiSatisfactionSurveysrentalRentalIdGetCollectionQueryKey(rentalId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
      >
    > = ({ signal }) =>
      apiSatisfactionSurveysrentalRentalIdGetCollection(
        rentalId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!rentalId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiSatisfactionSurveysrentalRentalIdGetCollectionInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
    >
  >;
export type ApiSatisfactionSurveysrentalRentalIdGetCollectionInfiniteQueryError =
  unknown;

/**
 * @summary Retrieves the collection of SatisfactionSurvey resources.
 */
export const useApiSatisfactionSurveysrentalRentalIdGetCollectionInfinite = <
  TData = Awaited<
    ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
  >,
  TError = unknown
>(
  rentalId: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiSatisfactionSurveysrentalRentalIdGetCollectionInfiniteQueryOptions(
      rentalId,
      options
    );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiSatisfactionSurveysrentalRentalIdGetCollectionQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
    >,
    TError = unknown
  >(
    rentalId: number,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiSatisfactionSurveysrentalRentalIdGetCollectionQueryKey(rentalId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
      >
    > = ({ signal }) =>
      apiSatisfactionSurveysrentalRentalIdGetCollection(
        rentalId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!rentalId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiSatisfactionSurveysrentalRentalIdGetCollectionQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
    >
  >;
export type ApiSatisfactionSurveysrentalRentalIdGetCollectionQueryError =
  unknown;

/**
 * @summary Retrieves the collection of SatisfactionSurvey resources.
 */
export const useApiSatisfactionSurveysrentalRentalIdGetCollection = <
  TData = Awaited<
    ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
  >,
  TError = unknown
>(
  rentalId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof apiSatisfactionSurveysrentalRentalIdGetCollection>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiSatisfactionSurveysrentalRentalIdGetCollectionQueryOptions(
      rentalId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a SatisfactionSurvey resource.
 * @summary Retrieves a SatisfactionSurvey resource.
 */
export const apiSatisfactionSurveysIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<SatisfactionSurveyJsonldReadSatisfactionSurvey>(
    { url: `/satisfaction_surveys/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiSatisfactionSurveysIdGetQueryKey = (id: string) => {
  return [`/satisfaction_surveys/${id}`] as const;
};

export const getApiSatisfactionSurveysIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiSatisfactionSurveysIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>
  > = ({ signal }) => apiSatisfactionSurveysIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiSatisfactionSurveysIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>
>;
export type ApiSatisfactionSurveysIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a SatisfactionSurvey resource.
 */
export const useApiSatisfactionSurveysIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiSatisfactionSurveysIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiSatisfactionSurveysIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiSatisfactionSurveysIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>
  > = ({ signal }) => apiSatisfactionSurveysIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiSatisfactionSurveysIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>
>;
export type ApiSatisfactionSurveysIdGetQueryError = void;

/**
 * @summary Retrieves a SatisfactionSurvey resource.
 */
export const useApiSatisfactionSurveysIdGet = <
  TData = Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiSatisfactionSurveysIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiSatisfactionSurveysIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates a StayCheckout resource.
 * @summary Creates a StayCheckout resource.
 */
export const apiStayCheckoutsPost = (
  apiStayCheckoutsPostBody:
    | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
    | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout,
  options?: SecondParameter<typeof instance>
) => {
  return instance<StayCheckoutJsonldReadStayCheckout>(
    { url: `/stay_checkouts`, method: 'POST', data: apiStayCheckoutsPostBody },
    options
  );
};

export const getApiStayCheckoutsPostMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiStayCheckoutsPost>>,
    TError,
    {
      data:
        | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
        | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiStayCheckoutsPost>>,
  TError,
  {
    data:
      | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
      | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiStayCheckoutsPost>>,
    {
      data:
        | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
        | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout;
    }
  > = (props) => {
    const { data } = props ?? {};

    return apiStayCheckoutsPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiStayCheckoutsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiStayCheckoutsPost>>
>;
export type ApiStayCheckoutsPostMutationBody =
  | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
  | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout;
export type ApiStayCheckoutsPostMutationError = void;

/**
 * @summary Creates a StayCheckout resource.
 */
export const useApiStayCheckoutsPost = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiStayCheckoutsPost>>,
    TError,
    {
      data:
        | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
        | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout;
    },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiStayCheckoutsPost>>,
  TError,
  {
    data:
      | StayCheckoutStayCheckoutCreateDtoJsonldCreateStayCheckout
      | StayCheckoutStayCheckoutCreateDtoCreateStayCheckout;
  },
  TContext
> => {
  const mutationOptions = getApiStayCheckoutsPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a StayCheckout resource.
 * @summary Retrieves a StayCheckout resource.
 */
export const apiStayCheckoutsIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<StayCheckoutJsonldReadStayCheckout>(
    { url: `/stay_checkouts/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiStayCheckoutsIdGetQueryKey = (id: string) => {
  return [`/stay_checkouts/${id}`] as const;
};

export const getApiStayCheckoutsIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiStayCheckoutsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>
  > = ({ signal }) => apiStayCheckoutsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiStayCheckoutsIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>
>;
export type ApiStayCheckoutsIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a StayCheckout resource.
 */
export const useApiStayCheckoutsIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiStayCheckoutsIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiStayCheckoutsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiStayCheckoutsIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>
  > = ({ signal }) => apiStayCheckoutsIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiStayCheckoutsIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>
>;
export type ApiStayCheckoutsIdGetQueryError = void;

/**
 * @summary Retrieves a StayCheckout resource.
 */
export const useApiStayCheckoutsIdGet = <
  TData = Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiStayCheckoutsIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiStayCheckoutsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Updates the StayCheckout resource.
 * @summary Updates the StayCheckout resource.
 */
export const apiStayCheckoutsIdPatch = (
  id: string,
  stayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout: StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout,
  options?: SecondParameter<typeof instance>
) => {
  return instance<StayCheckoutJsonldReadStayCheckout>(
    {
      url: `/stay_checkouts/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/merge-patch+json' },
      data: stayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout,
    },
    options
  );
};

export const getApiStayCheckoutsIdPatchMutationOptions = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiStayCheckoutsIdPatch>>,
    TError,
    { id: string; data: StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiStayCheckoutsIdPatch>>,
  TError,
  { id: string; data: StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiStayCheckoutsIdPatch>>,
    { id: string; data: StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout }
  > = (props) => {
    const { id, data } = props ?? {};

    return apiStayCheckoutsIdPatch(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiStayCheckoutsIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof apiStayCheckoutsIdPatch>>
>;
export type ApiStayCheckoutsIdPatchMutationBody =
  StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout;
export type ApiStayCheckoutsIdPatchMutationError = void;

/**
 * @summary Updates the StayCheckout resource.
 */
export const useApiStayCheckoutsIdPatch = <
  TError = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiStayCheckoutsIdPatch>>,
    TError,
    { id: string; data: StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout },
    TContext
  >;
  request?: SecondParameter<typeof instance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiStayCheckoutsIdPatch>>,
  TError,
  { id: string; data: StayCheckoutStayCheckoutUpdateDtoUpdateStayCheckout },
  TContext
> => {
  const mutationOptions = getApiStayCheckoutsIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Retrieves a StayCheckout resource.
 * @summary Retrieves a StayCheckout resource.
 */
export const apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet = (
  rentalId: number,
  startDate: string,
  endDate: string,
  customerId: number,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<StayCheckoutJsonldReadStayCheckout>(
    {
      url: `/stay_checkouts/${rentalId}/${startDate}/${endDate}/${customerId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryKey =
  (
    rentalId: number,
    startDate: string,
    endDate: string,
    customerId: number
  ) => {
    return [
      `/stay_checkouts/${rentalId}/${startDate}/${endDate}/${customerId}`,
    ] as const;
  };

export const getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
    >,
    TError = void
  >(
    rentalId: number,
    startDate: string,
    endDate: string,
    customerId: number,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryKey(
        rentalId,
        startDate,
        endDate,
        customerId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
      >
    > = ({ signal }) =>
      apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet(
        rentalId,
        startDate,
        endDate,
        customerId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(rentalId && startDate && endDate && customerId),
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<
        ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
    >
  >;
export type ApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetInfiniteQueryError =
  void;

/**
 * @summary Retrieves a StayCheckout resource.
 */
export const useApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetInfinite =
  <
    TData = Awaited<
      ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
    >,
    TError = void
  >(
    rentalId: number,
    startDate: string,
    endDate: string,
    customerId: number,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetInfiniteQueryOptions(
        rentalId,
        startDate,
        endDate,
        customerId,
        options
      );

    const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
      TData,
      TError
    > & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

export const getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
    >,
    TError = void
  >(
    rentalId: number,
    startDate: string,
    endDate: string,
    customerId: number,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof instance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryKey(
        rentalId,
        startDate,
        endDate,
        customerId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
      >
    > = ({ signal }) =>
      apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet(
        rentalId,
        startDate,
        endDate,
        customerId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(rentalId && startDate && endDate && customerId),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type ApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
    >
  >;
export type ApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryError =
  void;

/**
 * @summary Retrieves a StayCheckout resource.
 */
export const useApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet = <
  TData = Awaited<
    ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
  >,
  TError = void
>(
  rentalId: number,
  startDate: string,
  endDate: string,
  customerId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof apiStayCheckoutsRentalIdStartDateEndDateCustomerIdGet>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiStayCheckoutsRentalIdStartDateEndDateCustomerIdGetQueryOptions(
      rentalId,
      startDate,
      endDate,
      customerId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of StayDateAvailable resources.
 * @summary Retrieves the collection of StayDateAvailable resources.
 */
export const apiStayDateAvailablesGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiStayDateAvailablesGetCollection200>(
    { url: `/stay_date_availables`, method: 'GET', signal },
    options
  );
};

export const getApiStayDateAvailablesGetCollectionQueryKey = () => {
  return [`/stay_date_availables`] as const;
};

export const getApiStayDateAvailablesGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiStayDateAvailablesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>
  > = ({ signal }) =>
    apiStayDateAvailablesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiStayDateAvailablesGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>
>;
export type ApiStayDateAvailablesGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of StayDateAvailable resources.
 */
export const useApiStayDateAvailablesGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiStayDateAvailablesGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiStayDateAvailablesGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiStayDateAvailablesGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>
  > = ({ signal }) =>
    apiStayDateAvailablesGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiStayDateAvailablesGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>
>;
export type ApiStayDateAvailablesGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of StayDateAvailable resources.
 */
export const useApiStayDateAvailablesGetCollection = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getApiStayDateAvailablesGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a StayDateAvailable resource.
 * @summary Retrieves a StayDateAvailable resource.
 */
export const apiStayDateAvailablesIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<unknown>(
    { url: `/stay_date_availables/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiStayDateAvailablesIdGetQueryKey = (id: string) => {
  return [`/stay_date_availables/${id}`] as const;
};

export const getApiStayDateAvailablesIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiStayDateAvailablesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>
  > = ({ signal }) => apiStayDateAvailablesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiStayDateAvailablesIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>
>;
export type ApiStayDateAvailablesIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a StayDateAvailable resource.
 */
export const useApiStayDateAvailablesIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiStayDateAvailablesIdGetInfiniteQueryOptions(
    id,
    options
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiStayDateAvailablesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiStayDateAvailablesIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>
  > = ({ signal }) => apiStayDateAvailablesIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiStayDateAvailablesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>
>;
export type ApiStayDateAvailablesIdGetQueryError = void;

/**
 * @summary Retrieves a StayDateAvailable resource.
 */
export const useApiStayDateAvailablesIdGet = <
  TData = Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiStayDateAvailablesIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiStayDateAvailablesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Use `me` as identifier to fetch the requesting User's info
 * @summary Retrieves a User resource.
 */
export const apiUsersIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<UserJsonldReadMe>(
    { url: `/users/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiUsersIdGetQueryKey = (id: string) => {
  return [`/users/${id}`] as const;
};

export const getApiUsersIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiUsersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiUsersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiUsersIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiUsersIdGet>>> = ({
    signal,
  }) => apiUsersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiUsersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiUsersIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiUsersIdGet>>
>;
export type ApiUsersIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a User resource.
 */
export const useApiUsersIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiUsersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiUsersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiUsersIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiUsersIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiUsersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiUsersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiUsersIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiUsersIdGet>>> = ({
    signal,
  }) => apiUsersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiUsersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiUsersIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiUsersIdGet>>
>;
export type ApiUsersIdGetQueryError = void;

/**
 * @summary Retrieves a User resource.
 */
export const useApiUsersIdGet = <
  TData = Awaited<ReturnType<typeof apiUsersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiUsersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiUsersIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves the collection of Voucher resources.
 * @summary Retrieves the collection of Voucher resources.
 */
export const apiVouchersGetCollection = (
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<ApiVouchersGetCollection200>(
    { url: `/vouchers`, method: 'GET', signal },
    options
  );
};

export const getApiVouchersGetCollectionQueryKey = () => {
  return [`/vouchers`] as const;
};

export const getApiVouchersGetCollectionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiVouchersGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiVouchersGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>
  > = ({ signal }) => apiVouchersGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiVouchersGetCollectionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiVouchersGetCollection>>
>;
export type ApiVouchersGetCollectionInfiniteQueryError = unknown;

/**
 * @summary Retrieves the collection of Voucher resources.
 */
export const useApiVouchersGetCollectionInfinite = <
  TData = Awaited<ReturnType<typeof apiVouchersGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiVouchersGetCollectionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiVouchersGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof apiVouchersGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getApiVouchersGetCollectionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>
  > = ({ signal }) => apiVouchersGetCollection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiVouchersGetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiVouchersGetCollection>>
>;
export type ApiVouchersGetCollectionQueryError = unknown;

/**
 * @summary Retrieves the collection of Voucher resources.
 */
export const useApiVouchersGetCollection = <
  TData = Awaited<ReturnType<typeof apiVouchersGetCollection>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof apiVouchersGetCollection>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof instance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiVouchersGetCollectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves a Voucher resource.
 * @summary Retrieves a Voucher resource.
 */
export const apiVouchersIdGet = (
  id: string,
  options?: SecondParameter<typeof instance>,
  signal?: AbortSignal
) => {
  return instance<VoucherJsonldReadVoucher>(
    { url: `/vouchers/${id}`, method: 'GET', signal },
    options
  );
};

export const getApiVouchersIdGetQueryKey = (id: string) => {
  return [`/vouchers/${id}`] as const;
};

export const getApiVouchersIdGetInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof apiVouchersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiVouchersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiVouchersIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiVouchersIdGet>>
  > = ({ signal }) => apiVouchersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof apiVouchersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiVouchersIdGetInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiVouchersIdGet>>
>;
export type ApiVouchersIdGetInfiniteQueryError = void;

/**
 * @summary Retrieves a Voucher resource.
 */
export const useApiVouchersIdGetInfinite = <
  TData = Awaited<ReturnType<typeof apiVouchersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof apiVouchersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiVouchersIdGetInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiVouchersIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof apiVouchersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiVouchersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiVouchersIdGetQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiVouchersIdGet>>
  > = ({ signal }) => apiVouchersIdGet(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof apiVouchersIdGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiVouchersIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiVouchersIdGet>>
>;
export type ApiVouchersIdGetQueryError = void;

/**
 * @summary Retrieves a Voucher resource.
 */
export const useApiVouchersIdGet = <
  TData = Awaited<ReturnType<typeof apiVouchersIdGet>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiVouchersIdGet>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof instance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiVouchersIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
