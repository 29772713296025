/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes-étape
 * OpenAPI spec version: 1.0.0
 */

export type BookingJsonldReadBookingState =
  (typeof BookingJsonldReadBookingState)[keyof typeof BookingJsonldReadBookingState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookingJsonldReadBookingState = {
  pending: 'pending',
  unpaid: 'unpaid',
  partially_paid: 'partially_paid',
  paid: 'paid',
  canceled: 'canceled',
  refunded: 'refunded',
} as const;
