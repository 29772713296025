/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes-étape
 * OpenAPI spec version: 1.0.0
 */

export type CustomerStepReadCustomerStepStatus =
  (typeof CustomerStepReadCustomerStepStatus)[keyof typeof CustomerStepReadCustomerStepStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerStepReadCustomerStepStatus = {
  TODO: 'TODO',
  DONE: 'DONE',
  INFORMATION: 'INFORMATION',
} as const;
