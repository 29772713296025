export const badgeTheme = {
  baseStyle: {
    color: 'white',
    borderRadius: '4px',
    p: '2px 8px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  sizes: {
    lg: {
      p: '8px',
    },
  },
};
