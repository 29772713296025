import axios, { AxiosRequestConfig } from 'axios';

let locale: string | undefined = undefined;

export const setDataAccessLocale = (newLocale: string | undefined) => {
  locale = newLocale;
};

export const axiosInstance = axios.create({
  withCredentials: true,
  validateStatus: (status) => status >= 200 && status < 300,
});

const getHeaders = async (method: string) => ({
  Accept: 'application/ld+json',
  'X-LOCALE': locale,
  Authorization: process.env['NX_BASIC_AUTH'] ?? '',
  'Content-Type': method.toLowerCase().includes('patch') // this might be not usefull anymore as we get the deatails from the patch method directly now
    ? 'application/merge-patch+json'
    : 'application/json',
});

export const instance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const headers = {
    ...config.headers,
    ...(await getHeaders(config.method ?? '')),
  };

  const { data } = await axiosInstance({ ...config, headers, ...options });

  return data;
};
