/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes-étape
 * OpenAPI spec version: 1.0.0
 */

export type CustomerStepReadCustomerStepImportance =
  (typeof CustomerStepReadCustomerStepImportance)[keyof typeof CustomerStepReadCustomerStepImportance];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerStepReadCustomerStepImportance = {
  HIGH: 'HIGH',
  LOW: 'LOW',
} as const;
