/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes-étape
 * OpenAPI spec version: 1.0.0
 */

export type StayDateAvailableJsonldReadStayDateAvailableStayType =
  (typeof StayDateAvailableJsonldReadStayDateAvailableStayType)[keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StayDateAvailableJsonldReadStayDateAvailableStayType = {
  wk: 'wk',
  lw: 'lw',
  mw: 'mw',
  '1w': '1w',
  '2w': '2w',
  custom: 'custom',
} as const;
